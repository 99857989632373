import React, { useEffect, useState } from "react";

import InputSelect from "../../../../components/InputSelect";
import Input from "../../../../components/Input";
import InputRadio from "../../../../components/InputRadio";
import { createOptions } from '../../../../helpers/selects';
import { convertIntToMoney, convertMoenyToInt } from "../../../../helpers/conversions";
import Button from "../../../../components/Button";
import { calculateCustomizationTotal } from "../customizationCalculate";
import { hasPermission } from "../../../../services/auth";

const calculationTypeOptions = [
    { label: 'Pré-cadastrado', value: 'automatic' },
    { label: 'Manual', value: 'manual' }
];

const supplierSelectionModeOptions = [
    { label: 'Pelo menor preço', value: 'automatic' },
    { label: 'Manualmente', value: 'manual' }
];

const CustomizationSection = ({
    index,
    product,
    customizations,
    productCustomization,
    productCustomizations,
    setProductCustomizations,
    productQuantity,
    productSupplierCost
}) => {
    const [suppliers, setSuppliers] = useState([]);
    const [calculationPer, setCalculationPer] = useState('unit');
    const [customizationUnitCostError, setCustomizationUnitCostError] = useState('');
    const [customizationTotalCostError, setCustomizationTotalCostError] = useState('');

    const [tableOptions, setTableOptions] = useState([{ label: 'Tabela pré-cadastrada', value: 'registered' }]);

    useEffect(() => {
        if(product){
            getTableOptions();
            checkSpot();
        }
    }, [product]);

    const checkSpot = () => {
        let customizations = getCustomizations();
        if(productCustomization.spot_customization && customizations.filter(customization => customization.CustomizationTypeName === productCustomization.spot_customization ).length === 0) {
            deleteCustomization();
        }
    }

    const getTableOptions = () => {
        if(product.origin === 'integracao_spot' && product.spot_components.length > 0){
            setTableOptions(prev => [prev[0],  { label: 'Tabela fornecedor do produto', value: 'spot' }]);
        }
    }

    useEffect(() => {
        getSuppliers();
        getCalculationPer();
    }, [customizations,productCustomization.selectedCustomization]);

    useEffect(() => {
        handleQuantityChange(productQuantity);
    }, [productQuantity]);

    useEffect(() => {
        getCustomizationUnitCost();
        console.log('productCustomization.customizationUnitCostType', productCustomization.customizationUnitCostType);
    }, 
    [
        productCustomization.calculationType,
        productCustomization.selectedSupplierCustomization,
        productCustomization.selectedCustomization,
        productCustomization.quantity,
        productCustomization.quantityColors,
        productCustomization.supplierSelectionMode,
        suppliers
    ]);

    useEffect(() => {
        calculateCustomizationTotalCost();
    }, [
        product,
        productCustomization.table,
        productCustomization.spot_component,
        productCustomization.spot_location,
        productCustomization.spot_area,
        productCustomization.spot_customization,
        productCustomization.quantity,
        productCustomization.quantityColors,
        productCustomization.customizationUnitCost,
        productCustomization.customizationUnitCostType
    ]);

    const zeroUnitCost = () => {
        handleCustomizationUnitCostChange( `R$ 0,00` );
    }

    const getSuppliers = () => {
        let toSuppliers = [];
        let customization = customizations.filter(c => c.id === productCustomization.selectedCustomization);

        if(customization.length > 0){
            customization = customization[0];
            customization.companies.map(company => {
                const { id, customization_id, ...filteredPivot } = company.pivot;
                toSuppliers.push({
                    id: company.id,
                    name: company.trade_name,
                    type: "PJ",
                    ...filteredPivot
                });
            });
            customization.customers.map(customer => {
                const { id, customization_id, ...filteredPivot } = customer.pivot;
                toSuppliers.push({
                    id: customer.id,
                    name: customer.name,
                    type: "PF",
                    ...filteredPivot
                });
            });
        }

        setSuppliers(toSuppliers);
    }

    const calculateSpotCustomizationTotalCost = () => {
        let error = 'Preço não encontrado.';

        let scalePrices = getScalePrices();

        let total = 0;
        if(scalePrices.length > 0){
            scalePrices.map(scalePrice => {
                if(scalePrice.MinQt <= productQuantity){
                    total = scalePrice.Price * productQuantity * 100
                    error = '';
                }
            });
        }

        if(total > 0){
            total = total - (productSupplierCost * productQuantity);
        }

        setCustomizationTotalCostError(error);
        handleCustomizationTotalCostChange( `R$ ${convertIntToMoney(total)}`);
    }

    const calculateCustomizationTotalCost = () => {
        if(productCustomization.table === 'spot'){
            calculateSpotCustomizationTotalCost();
            return;
        }
        let total = calculateCustomizationTotal(
            productCustomization.quantity,
            convertMoenyToInt( productCustomization.customizationUnitCost),
            getCalculationPer(),
            productCustomization.customizationUnitCostType
        );
        let customization = customizations.filter(c => c.id === productCustomization.selectedCustomization);
        if(customization.length > 0){
            customization = customization[0];
            if(customization.multiply_colors){
                total = total * productCustomization.quantityColors;
            }
        }


        handleCustomizationTotalCostChange( `R$ ${convertIntToMoney(total)}`);
    }

    const handleTableChange = value => {
        handleParamChange('table', value);
    }

    const handleSpotComponentChange = value => {
        handleParamChange('spot_component', value);
    }

    const handleSpotLocationChange = value => {
        handleParamChange('spot_location', value);
    }

    const handleSpotAreaChange = value => {
        handleParamChange('spot_area', value);
    }

    const handleSpotCustomizationChange = value => {
        handleParamChange('spot_customization', value);
    }

    const handleCalculationTypeChange = value => {
        handleParamChange('calculationType', value);
    }

    const handleSupplierSelectionModeChange = value => {
        handleParamChange('supplierSelectionMode', value);
    }

    const handleSelectedCustomizationChange = value => {
        if(value !== productCustomization.selectedCustomization){
            handleSelectedSupplierCustomizationChange(null);
            zeroUnitCost();
        }
        handleParamChange('selectedCustomization', value);
    }

    const handleSelectedSupplierCustomizationChange = value => {
        let supplier = getSupplier(value);
        handleParamChange('selectedSupplierTypeCustomization', supplier ? supplier.type : null);
        zeroUnitCost();
        handleParamChange('selectedSupplierCustomization', value);
    }

    const handleQuantityColorsChange = value => {
        handleParamChange('quantityColors', value);
    }

    const handleQuantityChange = value => {
        handleParamChange('quantity', value);
    }

    const handleCustomizationUnitCostChange = value => {
        handleParamChange('customizationUnitCost', value);
    }

    const handleCustomizationUnitCostTypeChange = value => {
        handleParamChange('customizationUnitCostType', value);
    }

    const handleCustomizationTotalCostChange = value => {
        handleParamChange('customizationTotalCost', value);
    }

    const getCalculationPer = () => {
        let toCalculationPer = 'unit';
        let customization = customizations.filter(c => c.id === productCustomization.selectedCustomization);
        
        if(customization.length > 0){
            customization = customization[0];
            toCalculationPer = customization.calculation_per;
        }

        setCalculationPer(toCalculationPer);
        return toCalculationPer;
    }

    const handleParamChange = (param, value) => {
        setProductCustomizations(prev => {
            let toProductCustomizations = [];

            prev.map((c, i) => {
                if(i === index){
                    let updated = {...c};
                    updated[param] = value;
                    toProductCustomizations.push(updated);
                } else {
                    toProductCustomizations.push(c);
                }
            });

            return toProductCustomizations;
        });
    }

    const getSupplier = id => {
        let supplier = suppliers.filter(s => s.id === id);
        return supplier.length > 0 ? supplier[0] : null;
    }

    const getCustomizationUnitCost = () => {
        setCustomizationUnitCostError('');

        if(productCustomization.calculationType === 'manual') return;

        let cost = 0;
        let type = null;
        let error = 'Preço do fornecedor não encontrado.';
        let prices = [];
        const quantity = productCustomization.quantity;
        let thousands = quantity / 1000;
        thousands = Math.ceil(thousands);

        let customization = customizations.filter(c => c.id === productCustomization.selectedCustomization);

        if(customization.length === 0 || suppliers.length === 0) return;

        customization = customization[0];

        prices = sortPrices(suppliers);

        let selectedPrices = [];

        prices.map(price => {
            if(customization.calculation_per === 'unit' || customization.calculation_per === 'interval'){
                if(price.init_qtd <= quantity && price.final_qtd >= quantity){
                    selectedPrices.push(price);
                }
            } else if(price.thousand === thousands) {
                selectedPrices.push(price);
            }
        });    

        selectedPrices.map((selectedPrice, index) => {
            let companyId = productCustomization?.selectedSupplierCustomization ?? null;

            if(index === 0 && productCustomization.supplierSelectionMode === 'automatic'){
                companyId = selectedPrice.company_id;
                handleSelectedSupplierCustomizationChange(companyId);
            }

            if(selectedPrice.company_id === companyId){
                type = selectedPrice.cost_type;
                cost = selectedPrice.cost;
                error = '';
            }
        });

        handleCustomizationUnitCostChange( `R$ ${convertIntToMoney(cost)}` );
        handleCustomizationUnitCostTypeChange(type);
        setCustomizationUnitCostError(error);
    }
    
    const sortPrices = prices => {
        return prices.sort((a, b) => a.cost - b.cost);
    }

    const deleteCustomization = () => {
        let toProductCustomizations = [];
        productCustomizations.map((productCustomization, i) => {
            if(i !== index){
                toProductCustomizations.push(productCustomization);
            }
        });

        setProductCustomizations([...toProductCustomizations]);
    }

    const getLocations = () => {
        if(!product) return [];
        let component = product.spot_components.find(component => component.Name === productCustomization.spot_component);
        if(!component) return [];
        
        let options = [];

        component.Locations.map(location => {
            options.push({
                ...location,
                value: location.Name,
                label: location.Name
            });
        })
        
        return options;
    }

    const getCustomizations = () => {
        if(!product) return [];
        let locations = getLocations();
        let location = locations.find(location => location.Name === productCustomization.spot_location);
        if(!location) return [];

        let options = [];

        location.CustomizationAreas.map(customizationArea => {
            customizationArea.ProductCustomizationTables.map(productCustomizationTable => {
                options.push({
                    ...productCustomizationTable,
                    value: productCustomizationTable.CustomizationTypeName,
                    label: productCustomizationTable.CustomizationTypeName
                });
            })
        });

        return options;
    }

    const getAreas = () => {
        if(!product) return [];

        let customizations = getCustomizations();
        let customization = customizations.find(customization => customization.CustomizationTypeName === productCustomization.spot_customization);
        if(!customization) return [];
        
        let options = [];

        customization.ProductCustomizationTableOptions.map(productCustomizationTableOption => {
            let areaCM = productCustomizationTableOption.AreaCM;
            if (!options.some(option => option.value === areaCM)) {
                options.push({
                    ...productCustomizationTableOption,
                    value: areaCM,
                    label: areaCM.replace(/(\d+)(?=\s*x|\s*$)/g, '$1cm')
                });
            }
        });

        return options;
    }

    const getScalePrices = () => {
        if(!product) return [];
        let quantityColors = parseInt(productCustomization.quantityColors);

        if(quantityColors === 0) return [];
        
        let customizations = getCustomizations();
        let customization = customizations.find(customization => customization.CustomizationTypeName === productCustomization.spot_customization);
        if(!customization) return [];

        let productCustomizationTableOptions = customization.ProductCustomizationTableOptions;

        productCustomizationTableOptions.sort((a, b) => a.Colors - b.Colors);

        let productCustomizationTableOption = productCustomizationTableOptions.find(productCustomizationTableOption => productCustomizationTableOption.AreaCM === productCustomization.spot_area && productCustomizationTableOption.Colors >= quantityColors);

        if(!productCustomizationTableOption) return [];

        return productCustomizationTableOption.ScalePrices;
    }

    return (
        <>
            <div className="modal-section-title">
                <div className="left">
                    <h2>Gravação</h2>
                    <Button type={`transparent`} size={`small`} svg={`delete-small`} action={deleteCustomization} />
                </div>
                <div className="right">
                    {tableOptions.length > 1 &&
                        <InputRadio
                            className={`no-margin`}
                            options={tableOptions}
                            value={productCustomization.table}
                            change={handleTableChange}
                        />
                    }
                </div>
            </div>

            {product && productCustomization.table === 'spot' &&
                <div className="section">
                    <div className="row">
                        <div className="col-4">
                            <InputSelect
                                label={`Componente`}
                                options={createOptions(product.spot_components, 'Name', 'Name')}
                                value={productCustomization.spot_component}
                                change={handleSpotComponentChange}
                            />
                        </div>
                        <div className="col-4">
                            {productCustomization.spot_component &&
                                <InputSelect
                                    label={`Localização`}
                                    options={getLocations()}
                                    value={productCustomization.spot_location}
                                    change={handleSpotLocationChange}
                                />
                            }
                        </div>
                        <div className="col-4">
                            {productCustomization.spot_location &&
                                <InputSelect
                                    label={`Gravação`}
                                    options={getCustomizations()}
                                    value={productCustomization.spot_customization}
                                    change={handleSpotCustomizationChange}
                                />
                                
                            }
                        </div>
                        <div className="col-4">
                            {productCustomization.spot_customization &&
                                <InputSelect
                                    label={`Área da gravação`}
                                    options={getAreas()}
                                    value={productCustomization.spot_area}
                                    change={handleSpotAreaChange}
                                />
                            }
                        </div>
                        <div className="col-4">
                            {productCustomization.spot_area &&
                                <Input
                                    label={`Num. de cores`}
                                    value={productCustomization.quantityColors}
                                    change={handleQuantityColorsChange}
                                    mask={`convertToInt`}
                                />
                            }
                        </div>
                        <div className="col-4">
                            {productCustomization.spot_customization &&
                                <Input
                                    label={`Total`}
                                    value={productCustomization.customizationTotalCost}
                                    disabled={true}
                                    error={customizationTotalCostError}
                                />
                            }
                        </div>
                    </div>
                </div>
            }
        
            {productCustomization.table === 'registered' &&
                <div className="section">
                    <div className="row">
                        <div className="col-4">
                            <InputSelect 
                                options={createOptions(customizations, 'name', 'id', true)}
                                value={productCustomization.selectedCustomization}
                                change={handleSelectedCustomizationChange}
                                label={`Gravação`}
                            />
                        </div>
                        {hasPermission('show_customization_supplier_in_budget') &&
                            <>
                                <div className="col-4">
                                    <InputSelect
                                        label={`Escolha do fornecedor`}
                                        options={supplierSelectionModeOptions}
                                        value={productCustomization.supplierSelectionMode}
                                        change={handleSupplierSelectionModeChange}
                                    />
                                </div>
                                <div className="col-4">
                                    <InputSelect 
                                        label={`Fornecedor`}
                                        isDisabled={productCustomization.supplierSelectionMode === 'automatic'}
                                        options={createOptions(suppliers, 'name', 'id', true)}
                                        value={productCustomization.selectedSupplierCustomization}
                                        change={handleSelectedSupplierCustomizationChange}
                                    />
                                </div>
                            </>
                        }
                        <div className="col-3">
                            <Input
                                label={`Num. de cores`}
                                value={productCustomization.quantityColors}
                                change={handleQuantityColorsChange}
                                mask={`convertToInt`}
                            />
                        </div>
                        {hasPermission('show_customization_supplier_price_in_budget') &&
                            <>
                                <div className="col-3">
                                    <InputSelect
                                        label={`Origem do custo`}
                                        options={calculationTypeOptions}
                                        value={productCustomization.calculationType}
                                        change={handleCalculationTypeChange}
                                    />
                                </div>
                                <div className="col-3">
                                    <Input
                                        label={calculationPer === 'unit' ? 'Custo' : 'Custo milheiro'} 
                                        // label={calculationPer === 'unit' ? 'Custo unit.' : 'Custo milheiro'} 
                                        value={productCustomization.customizationUnitCost}
                                        change={handleCustomizationUnitCostChange}
                                        mask={`convertToMoney`}
                                        disabled={productCustomization.calculationType === 'automatic'}
                                        error={customizationUnitCostError}
                                    />
                                </div>
                                <div className="col-3">
                                    <Input
                                        label={`Total`}
                                        value={productCustomization.customizationTotalCost}
                                        disabled={true}
                                    />
                                </div>
                            </>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default CustomizationSection;